import React, { useLayoutEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { motion } from 'framer-motion';
// import { TwitterTweetEmbed } from 'react-twitter-embed';
// import { IntercomProvider, useIntercom } from 'react-use-intercom';
// import { LocomotiveScrollProvider } from 'react-locomotive-scroll';
import Header, { LandingHeaderLinks } from '../../components/header/header';
import Footer from '../../components/footer/footer';
import 'react-toastify/dist/ReactToastify.css';
import './landing.scss';

import PhoneImage from './images/phone2.webp';
import TopImage from './images/top.webp';
import LeftImage from './images/bottom.webp';
import BottomImage from './images/left.webp';
import GooglePlay from './images/Google-play.svg';
import AppStore from './images/Appstore.svg';
import PSNCard from './images/psn-card.svg';
import AmazonCard from './images/amazon-card.svg';
import AppleCard from './images/apple-card.svg';

/* Framer Motion Variant Options */
// header animation
const headerMediaAnimation = {
  container: {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.28,
        delay: 0.1,
        delayChildren: 0.2,
        duration: 0.3,
      },
    },
  },

  firstImage: {
    hidden: { opacity: 0, scale: 0.5, y: 350 },
    show: { opacity: 1, scale: 1, y: 0 },
  },

  secondImage: {
    hidden: {
      opacity: 0,
      // x: -100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },

  thirdImage: {
    hidden: {
      opacity: 0,
      // x: 100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },

  fourthImage: {
    hidden: {
      opacity: 0,
      // x: -100,
      clipPath: 'inset(10% 50% 90% 50% round 10px)',
    },
    show: {
      opacity: 1,
      // x: 0,
      clipPath: 'inset(0% 0% 0% 0% round 10px)',
    },
  },
};

/* end Framer Motion Options */

function Landing() {
  // const ref = useRef(null);
  // const isInView = useInView(ref, { once: true });

  // const options = {
  //   smooth: true,
  // };

  const el = useRef();
  const q = gsap.utils.selector(el);
  const tl = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .from(q('.hidetextref'), {
          duration: 1.5,
          y: '150%',
          stagger: 0.6,
          ease: 'power4.out',
          delay: 0.6,
        })
        .from(q('.hidepara'), {
          duration: 0.8,
          y: '150%',
          stagger: 0.6,
          delay: 1.3,
        });
    }, el);

    return () => ctx.revert();
  }, [q]);

  return (
    // <LocomotiveScrollProvider options={options} containerRef={ref}>
    <main>
      <div className="c-home">
        <section className="c-manifest">
          <div className="c-grid" />
          <div className="c-overlay" />
          <Header>
            <LandingHeaderLinks />
          </Header>
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <motion.div
                  // initial={{ y: 400 }}
                  // animate={{ y: 0 }}
                  // transition={{ duration: 0.45 }}
                  className="c-manifest__text"
                  ref={el}
                >
                  {/* <Link to="/referral" className="referral">
                    Refer a friend & Get 25%<span>→</span>
                  </Link> */}
                  <h2>
                    <span className="hidetextref">
                      Where Your Wishes Come True!
                    </span>

                    {/* <span>
                      cash on
                      <i className="word-wrap">
                        <span className="animatedword animated-in">
                          {' '}
                          <img src={amazonImage} alt="amazon" /> amazon
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#04c167' }}
                        >
                          {' '}
                          <img src={uberImage} alt="uber" /> uberEats
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#ff615d' }}
                        >
                          {' '}
                          <img src={tinderImage} alt="tinder" /> tinder
                        </span>
                        <span
                          className="animatedword"
                          style={{ color: '#007ec9' }}
                        >
                          <img src={psnImage} alt="psn network" /> Playstation
                        </span>
                      </i>
                    </span> */}
                  </h2>
                  <p>
                    <span className="hidepara">
                      Let fans fulfill your wish list and spend instantly with a
                      Gotok card.
                    </span>
                  </p>
                  {/* <Email /> */}
                  {/* <img
                    src={ManifestCurrencySVG}
                    alt="currency"
                    className="c-manifest__illustration c-manifest__illustration--currency"
                  /> */}
                  <div className="c-manifest__buttons">
                    <a
                      className=""
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=app.gotok"
                      rel="noreferrer"
                    >
                      {' '}
                      <img
                        src={GooglePlay}
                        alt="Playstore download"
                        className=""
                      />{' '}
                    </a>
                    <a
                      className=""
                      target="_blank"
                      href="https://apps.apple.com/ng/app/gotok/id6444884980"
                      rel="noreferrer"
                    >
                      {' '}
                      <img
                        src={AppStore}
                        alt="Appstore download"
                        className=""
                      />{' '}
                    </a>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <section className="c-media">
            <div className="l-wrapper">
              <div className="l-row">
                <motion.div
                  variants={headerMediaAnimation.container}
                  initial="hidden"
                  animate="show"
                  className="c-media__images-wrap"
                >
                  <motion.img
                    variants={headerMediaAnimation.firstImage}
                    src={PhoneImage}
                    alt=""
                    className="c-media__image c-media__image--1"
                  />
                  <motion.img
                    variants={headerMediaAnimation.secondImage}
                    src={TopImage}
                    alt=""
                    className="c-media__image c-media__image--2"
                  />
                  <motion.img
                    variants={headerMediaAnimation.thirdImage}
                    src={LeftImage}
                    alt=""
                    className="c-media__image c-media__image--3"
                  />
                  <motion.img
                    variants={headerMediaAnimation.fourthImage}
                    src={BottomImage}
                    alt=""
                    className="c-media__image c-media__image--4"
                  />
                </motion.div>
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  //   delay: 0.1, //   ease: [0, 0.71, 0.2, 1.01],
                  src={AmazonCard}
                  alt=""
                  className="c-media__image c-media__image--5"
                />
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  src={PSNCard}
                  alt=""
                  className="c-media__image c-media__image--6"
                />
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 1,
                    delay: 0.13,
                    ease: [0, 0.71, 0.2, 1.01],
                  }}
                  src={AppleCard}
                  alt=""
                  className="c-media__image c-media__image--7"
                />
              </div>
            </div>
          </section>
        </section>

        {/* <section className="c-cta">
          <div className="l-wrapper">
            <div className="l-row">
              <div className="l-col-12">
                <div className="c-cta__text">
                  <img
                    src={CtaCoinSVG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--coin"
                  />
                  <img
                    src={CtaGlitzSVG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--glitz"
                  />
                  <img
                    src={CtaCardsPNG}
                    alt=""
                    className="c-cta__illustration c-cta__illustration--cards"
                  />
                  <h3>
                    Download the Gotok app for the easier way to make
                    international payment online
                  </h3>
                  <a
                    href="https://web.gotok.app/"
                    className="c-cta__link"
                    alt="link to download gotok"
                  >
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <Footer />
      </div>
    </main>
    // </LocomotiveScrollProvider>
  );
}

export default Landing;
